var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "d-inline" },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "b-modal",
              rawName: "v-b-modal.resetpassword",
              modifiers: { resetpassword: true }
            }
          ],
          staticClass: "ma-0 pa-0 d-inline"
        },
        [
          _vm._t("default", function() {
            return [
              _c(
                "b-button",
                { staticClass: "link_styles", attrs: { variant: "link" } },
                [_vm._v("Forgot Password")]
              )
            ]
          })
        ],
        2
      ),
      _c(
        "b-modal",
        {
          attrs: {
            id: "resetpassword",
            title: "Reset Password",
            "ok-title": "Send New Password",
            "no-close-on-backdrop": "",
            "no-close-on-esc": ""
          },
          on: {
            hidden: function($event) {
              _vm.email = null
            },
            shown: function($event) {
              _vm.email = null
            },
            ok: _vm.sendForm
          }
        },
        [
          _c(
            "b-form-group",
            { attrs: { label: "Email", "label-align": "left" } },
            [
              _c("b-input", {
                attrs: { required: "", type: "email" },
                model: {
                  value: _vm.email,
                  callback: function($$v) {
                    _vm.email = $$v
                  },
                  expression: "email"
                }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }