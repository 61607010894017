var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-form",
    {
      ref: "loginForm",
      on: {
        submit: function($event) {
          $event.preventDefault()
          return _vm.verify.apply(null, arguments)
        }
      }
    },
    [
      _c(
        "b-form-group",
        {
          attrs: {
            label: "Email:",
            "label-for": "email",
            "label-cols": "12",
            "label-cols-md": "4",
            "label-class": "text-right login-label"
          }
        },
        [
          _c("b-form-input", {
            attrs: {
              id: "email",
              type: "email",
              placeholder: "Enter email",
              required: ""
            },
            model: {
              value: _vm.form.email,
              callback: function($$v) {
                _vm.$set(_vm.form, "email", $$v)
              },
              expression: "form.email"
            }
          })
        ],
        1
      ),
      _c(
        "b-form-group",
        {
          attrs: {
            label: "Password:",
            "label-for": "passwd",
            "label-cols": "12",
            "label-cols-md": "4",
            "label-class": "text-right login-label"
          }
        },
        [
          _c("b-form-input", {
            attrs: {
              id: "passwd",
              type: "password",
              placeholder: "Enter password",
              required: ""
            },
            model: {
              value: _vm.form.password,
              callback: function($$v) {
                _vm.$set(_vm.form, "password", $$v)
              },
              expression: "form.password"
            }
          })
        ],
        1
      ),
      _c(
        "b-button",
        {
          attrs: {
            type: "submit",
            variant: "primary",
            disabled: _vm.loading || !_vm.formIsValid
          }
        },
        [_vm._v("Log In")]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }