var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-container",
    { staticClass: "home" },
    [
      _c(
        "b-overlay",
        { attrs: { show: _vm.loading } },
        [
          _c(
            "b-row",
            [
              _c(
                "b-col",
                { attrs: { cols: "8", offset: "2" } },
                [
                  _c(
                    "b-card",
                    { staticClass: "mb-4" },
                    [
                      _c("h2", { staticClass: "w-100 text-center mb-4" }, [
                        _vm._v(" Welcome to the Streamlined Dashboard ")
                      ]),
                      _c("Login")
                    ],
                    1
                  ),
                  _c("ForgotPassword"),
                  _c("p", { staticClass: "small text--white" }, [
                    _vm._v(
                      "* By logging in and using this portal the client understands that Third party access may be used for the purpose of obtaining necessary data to meet agreed upon terms and the dashboard is for informational purposes only to help understand financial information, and in no way substitutes the accurate financial reports presented by SBS at year end for Tax Preparation."
                    )
                  ])
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }